import { useEffect } from "react";
import errorimg from "../img/404img.webp";
import { NavLink } from "react-router-dom";
const ErrorPage = () => {
  useEffect(() => {
    document.title = "404 | Make Me Master";
  }, []);
  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Oops!</h1>
                <h4>
                  We Were Unable To Find The Page That You Are Looking For...
                </h4>
                <NavLink className="btn subscribe-btn" to="/Home">
                  <h3>Go Home</h3>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={errorimg} alt="404" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ErrorPage;
