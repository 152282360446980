import { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import {
  account,
  databases,
  DatabaseId,
  AdminCollectionId,
} from "../../AppwriteConfig";
import adminloginimg from "../../img/adminloginimg.webp";
import Swal from "sweetalert2";
const AdminLogin = () => {
  useEffect(() => {
    document.title = "Admin Login | Make Me Master";
  }, []);
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const loginUser = async (e) => {
    e.preventDefault();
    setIsLoggingIn(true);
    // Check if all fields are filled
    for (const key in user) {
      if (user[key] === "") {
        Swal.fire("Error", "Please Fill In All Fields!", "error");
        setIsLoggingIn(false);
        return;
      }
    }
    try {
      // Fetch User Details From The Database
      const dataPromise = databases.listDocuments(
        DatabaseId,
        AdminCollectionId
      );
      const dataResponse = await dataPromise;
      // console.log(dataResponse);
      const userExists = dataResponse.documents.find(
        (doc) =>
          doc.AdminId === user.id &&
          doc.AdminEmail === user.email &&
          doc.AdminPassword === user.password
      );

      if (userExists) {
        // eslint-disable-next-line no-unused-vars
        const response = await account.createEmailSession(
          user.email,
          user.password
        );
        // console.log(response);
        navigate("/Dashboard/Admin");
      } else {
        Swal.fire("Invalid Credentials", "Please Enter Valid Details", "error");
        setIsLoggingIn(false);
        return;
      }
    } catch (error) {
      console.error(error);
      Swal.fire("Invalid Credentials", "Please Enter Valid Details", "error");
      setIsLoggingIn(false);
      return;
    }
    setIsLoggingIn(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <div className="inner">
                  <h1>Welcome To Admin's Login Portal</h1>
                  <br />
                  <form method="post" className="register-form">
                    <div className="mb-3">
                      <label className="form-label">Your Admin ID</label>
                      <input
                        type="text"
                        className="form-control"
                        required
                        onChange={(e) => {
                          setUser({
                            ...user,
                            id: e.target.value.toUpperCase(),
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        required
                        onChange={(e) => {
                          setUser({
                            ...user,
                            email: e.target.value,
                          });
                        }}
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Your Password</label>
                      <input
                        type={showPassword ? "text" : "password"} // Toggle input type
                        className="form-control"
                        required
                        onChange={(e) => {
                          setUser({
                            ...user,
                            password: e.target.value,
                          });
                        }}
                      />
                      <button
                        type="button"
                        className="btn btn-success btn-sm mt-2"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide Password" : "Show Password"}
                      </button>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-warning"
                      onClick={loginUser}
                      disabled={isLoggingIn}
                    >
                      {isLoggingIn ? "Logging In..." : "Log In"}
                    </button>
                    <NavLink
                      to="/ForgetPassword"
                      className="btn btn-danger loginbtn"
                    >
                      {" "}
                      Forgot Password?
                    </NavLink>
                    <NavLink
                      to="/AdminRegistration"
                      className="btn btn-info loginbtn"
                    >
                      {" "}
                      New User? Register Now
                    </NavLink>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={adminloginimg} alt="adminloginimg" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminLogin;
