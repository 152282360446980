import { useState, useEffect } from "react";
import {
  databases,
  DatabaseId,
  InstituteCollectionId,
} from "../../../AppwriteConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import modifyInstituteimg from "../../../img/institute.webp";
import AdminSidebar from "../../../components/AdminSidebar";

const ModifyInstitute = () => {
  useEffect(() => {
    document.title = "Modify Institute | Make Me Master";
  }, []);

  const urlParams = new URLSearchParams(window.location.search);
  const docId = urlParams.get("docId");
  const user = localStorage.getItem("UserEmail");
  const navigate = useNavigate();
  const [modifyInstitute, setModifyInstitute] = useState({
    InstituteName: "",
    InstituteType: "",
    InstituteRegdNo: "",
    InstituteState: "",
    InstituteCity: "",
    InstituteAddress: "",
    InstituteZip: "",
    InstituteDistrict: "",
    InstituteCountry: "",
    CreatedBy: user,
  });

  const [fetchData, setFetchData] = useState({});
  const [doesUserHaveCrudPower, setDoesUserHaveCrudPower] = useState(false);
  const [isBeingModified, setIsBeingModified] = useState(false);

  const handleFetchingData = async () => {
    try {
      const fetchedData = await databases.getDocument(
        DatabaseId,
        InstituteCollectionId,
        docId
      );
      if (fetchedData.CreatedBy === user) {
        setDoesUserHaveCrudPower(true);
        setFetchData(fetchedData);
      } else {
        Swal.fire(
          "You Are Not Authorized To Modify Information!",
          "You Haven't Created This Institute",
          "error"
        );
        return;
      }
    } catch (error) {
      console.error("Error Fetching Data:", error);
    }
  };

  const handleModify = async (e) => {
    e.preventDefault();
    setIsBeingModified(true);
    try {
      // Update in the database
      if (doesUserHaveCrudPower) {
        await databases.updateDocument(
          DatabaseId,
          InstituteCollectionId,
          docId,
          modifyInstitute
        );
        await Swal.fire(
          "Institute Data Modified Successfully!",
          "Now You Can Select This Institute From Everywhere.",
          "success"
        );

        navigate("/Dashboard/Admin/CreateInstitute");
      } else {
        await Swal.fire(
          "You Are Not Authorized To Modify Information!",
          "You Haven't Created This Institute",
          "error"
        );
        return;
      }
    } catch (error) {
      console.log("Error During Modifying:", error);
      Swal.fire("Failed To Modify!", `${error.message}`, "error");
      return;
    } finally {
      setIsBeingModified(false);
    }
  };

  useEffect(() => {
    handleFetchingData();
  }, []);

  useEffect(() => {
    // Set the values from fetchData to modifyInstitute when fetchData changes.
    setModifyInstitute({
      InstituteName: fetchData.InstituteName || "",
      InstituteType: fetchData.InstituteType || "",
      InstituteRegdNo: fetchData.InstituteRegdNo || "",
      InstituteAddress: fetchData.InstituteAddress || "",
      InstituteState: fetchData.InstituteState || "",
      InstituteCity: fetchData.InstituteCity || "",
      InstituteDistrict: fetchData.InstituteDistrict || "",
      InstituteZip: fetchData.InstituteZip || "",
      InstituteCountry: fetchData.InstituteCountry || "",
      CreatedBy: user,
    });
  }, [fetchData]);

  return (
    <section className="add-margin-top">
      <AdminSidebar />
      <div className="row" id="topsize">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Modify An Institute</h1>
                <p>Modify Your Institute By Filling Up This Single Form!</p>
                <form
                  className="row g-3 register-form mt-4"
                  onSubmit={handleModify}
                >
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4" className="form-label">
                      Institute Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteName || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteName: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputPassword4" className="form-label">
                      Institute Type
                    </label>
                    <select
                      id="inputType"
                      className="form-select"
                      value={fetchData.InstituteType || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteType: e.target.value,
                        });
                      }}
                    >
                      <option value=" ">Choose...</option>
                      <option value="Public">Public</option>
                      <option value="Private">Private</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label className="form-label">Registration Number</label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteRegdNo || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteRegdNo: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-7">
                    <label htmlFor="inputAddress" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteAddress || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteAddress: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputCity" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteCity || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteCity: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputState" className="form-label">
                      State
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteState || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteState: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">District</label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteDistrict || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteDistrict: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputZip" className="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteZip || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteZip: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputCountry" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={fetchData.InstituteCountry || ""}
                      required
                      onChange={(e) => {
                        setFetchData({
                          ...fetchData,
                          InstituteCountry: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-info btn-lg">
                      {isBeingModified ? "Modifying..." : "Modify"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img
                  src={modifyInstituteimg}
                  alt="modifyInstituteimg"
                  id="animateimg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ModifyInstitute;
