import { useEffect } from "react";
import cardContents from "../components/PricingContent";
import tick from "../img/tick.gif";
import { useNavigate } from "react-router-dom";
const Pricings = () => {
  useEffect(() => {
    document.title = "Pricing | Make Me Master";
  }, []);
  const navigate = useNavigate();
  function handleSubscription() {
    navigate("/Contact");
  }

  return (
    <section>
      <h1 className="text-center mt-5 mb-5">Our Pricing</h1>
      <div className="row">
        {cardContents.map((card) => (
          <div className="col-sm-4" key={card.id}>
            <div className="card pricing-cards">
              <div className="card-body">
                <h2>{card.title}</h2>
                <center>
                  <h3 className="card-price">{card.price}</h3>
                  <h3 className="card-ex">
                    Exclusive Feature:
                    <br />
                    {card.exfeature}
                  </h3>
                </center>
                <img src={card.image} alt={card.title} id="animateimg" />
                <p className="card-text">{card.desc}</p>
                <h4>Benefits:</h4>
                <div className="benefits">
                  <img src={tick} className="tick" alt="tick" />
                  {card.benefit1}
                </div>
                <div className="benefits">
                  <img src={tick} className="tick" alt="tick" />
                  {card.benefit2}
                </div>
                <div className="benefits">
                  <img src={tick} className="tick" alt="tick" />
                  {card.benefit3}
                </div>
                <div className="benefits">
                  <img src={tick} className="tick" alt="tick" />
                  {card.benefit4}
                </div>
                <button className="btn subscribe-btn" onClick={handleSubscription}>
                  <h5>Subscribe To {card.title}</h5>
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Pricings;
