import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import gototopimg from "../img/gototop.webp";
const BackToTop = () => {
  const location = useLocation();
  const [isVisible, setIsVisible] = useState(false);

  // Show the button when the user scrolls down
  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Scroll to the top when the button is clicked
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    // Hide the button when the route changes
    setIsVisible(false);
  }, [location.pathname]);

  return (
    <>
      {isVisible && (
        <button className="back-to-top-button" onClick={scrollToTop}>
          <img src={gototopimg} alt="gototop" className="logo" />
        </button>
      )}
    </>
  );
};

export default BackToTop;
