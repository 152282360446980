import React, { useEffect, useState } from "react";
import verifyimg from "../../img/verifyimg.webp";
import { account } from "../../AppwriteConfig";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  useEffect(() => {
    document.title = "Verify Email | Make Me Master";
  }, []);

  const navigate = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const secret = urlParams.get("secret");
  const [verificationStatus, setVerificationStatus] = useState("");

  const handleVerification = async () => {
    try {
      setVerificationStatus("We Are Verifying Your Account...");
      await account.updateVerification(userId, secret);
      Swal.fire(
        "Your Account Is Now Verified.",
        "Please Login To Continue!",
        "success"
      );
      setVerificationStatus(
        "We have Verified Your Account! Welcome To Make Me Master."
      );
      navigate("/Login");
    } catch (error) {
      console.error(error);
      Swal.fire(
        "We Were Unable To Verify Your Account!",
        `${error.message}`,
        "error"
      );
      setVerificationStatus(
        "We Were Unable To Verify Your Account!Please Try Again Later..."
      );
      return;
    }
  };

  useEffect(() => {
    handleVerification(); // Run verification on component mount
  }, []);

  return (
    <section>
      <div className="row" id="topsize">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Welcome To Make Me Master's Verification Section!</h1>
                <h4>{verificationStatus}</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={verifyimg} alt="verify" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default VerifyEmail;
