import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div>
      <NavLink className="nav-link" to="/">
        <h5 className="text-center" style={{ color: "grey" }}>
          Copyright Make Me Master.All Rights Reserved.
        </h5>
      </NavLink>
    </div>
  );
};

export default Footer;
