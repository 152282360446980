import { useState, useEffect } from "react";
import {
  databases,
  DatabaseId,
  InstituteCollectionId,
} from "../../../AppwriteConfig";
import Swal from "sweetalert2";
import instituteimg from "../../../img/institute.webp";
import AdminSidebar from "../../../components/AdminSidebar";
import { useNavigate } from "react-router-dom";

const CreateClass = () => {
  useEffect(() => {
    document.title = "Create Institute | Make Me Master";
  }, []);
  const navigate = useNavigate();

  const user = localStorage.getItem("UserEmail");

  const [institute, setInstitute] = useState({
    Name: "",
    Type: "",
    RegdNo: "",
    State: "",
    City: "",
    Address: "",
    ZipCode: "",
    District: "",
    Country: "",
    CreatedBy: user,
  });

  const [isbeingCreated, setIsBeingCreated] = useState(false);
  const [isbeingDeleted, setIsBeingDeleted] = useState(false);
  const [fetchedData, setFetchedData] = useState({});

  const [doesUserHaveCrudPower, setDoesUserHaveCrudPower] = useState(false);

  //Fetching Results
  const handleFetchingInstitutes = async () => {
    try {
      const fetchedData = await databases.listDocuments(
        DatabaseId,
        InstituteCollectionId
      );
      setFetchedData(fetchedData);
      if (fetchedData.documents.length > 0) {
        for (let i = 0; i < fetchedData.documents.length; i++) {
          if (fetchedData.documents[i].CreatedBy === user) {
            setDoesUserHaveCrudPower(true);
          }
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Registration Function Starts Here
  const registerInstitute = async (e) => {
    e.preventDefault();
    setIsBeingCreated(true);
    // Check if all fields are filled
    for (const key in institute) {
      if (institute[key] === "") {
        Swal.fire("Error", `Please Fill In All Fields in ${key}!`, "error");
        setIsBeingCreated(false);
        return;
      }
    }
    const docId = Date.now().toString();
    try {
      // Fetching All Datas
      if (fetchedData.documents.length > 0) {
        for (let i = 0; i < fetchedData.documents.length; i++) {
          if (
            fetchedData.documents[i].InstituteName === institute.Name ||
            fetchedData.documents[i].InstituteRegdNo === institute.RegdNo
          ) {
            Swal.fire("Error", "Institute Already Exists!", "error");
            setIsBeingCreated(false);
            return;
          }
          if (fetchedData.documents[i].CreatedBy === user) {
            setDoesUserHaveCrudPower(true);
          }
        }
      }

      //Storing Datas In A Var.
      const documentData = {
        InstituteName: institute.Name,
        InstituteType: institute.Type,
        InstituteRegdNo: institute.RegdNo,
        InstituteAddress: institute.Address,
        InstituteState: institute.State,
        InstituteCity: institute.City,
        InstituteDistrict: institute.District,
        InstituteZip: institute.ZipCode,
        InstituteCountry: institute.Country,
        CreatedBy: user,
      };

      // Register in the database
      await databases.createDocument(
        DatabaseId,
        InstituteCollectionId,
        docId,
        documentData
      );
      await Swal.fire(
        "Institute Created Successfully!",
        "Now You Can Select This Institute From Everywhere.",
        "success"
      );
      await handleFetchingInstitutes();
    } catch (error) {
      console.log(error); // Failure
      Swal.fire("Failed!", `${error.message}`, "error");
    } finally {
      setIsBeingCreated(false);
    }
  };

  // CRUD Operations

  //Delete
  const handleDelete = async (instId) => {
    setIsBeingDeleted(true);
    if (doesUserHaveCrudPower) {
      try {
        await databases.deleteDocument(
          DatabaseId,
          InstituteCollectionId,
          instId
        );
        Swal.fire(
          "Institute Deleted Successfully",
          "Continue Creating New Institutes",
          "success"
        );
        handleFetchingInstitutes();
      } catch (error) {
        console.log(error);
        Swal.fire("Institute Can't Be Deleted!", `${error.message}`, "error");
      } finally {
        setIsBeingDeleted(false);
      }
    } else {
      Swal.fire(
        "Institute Can't Be Deleted!",
        "You Haven't Created This Institute.Only Creator Of This Institute Can Delete!",
        "error"
      );
      setIsBeingDeleted(false);
      return;
    }
  };

  //Modify
  const handleModify = async (instituteId) => {
    if (doesUserHaveCrudPower) {
      navigate(`/Dashboard/Admin/ModifyInstitute?docId=${instituteId}`);
    } else {
      Swal.fire(
        "Institute Can't Be Modified!",
        "You Haven't Created This Institute.Only Creator Of This Institute Can Modify!",
        "error"
      );
      return;
    }
  };

  useEffect(() => {
    handleFetchingInstitutes();
  }, []);

  return (
    <section className="add-margin-top">
      <AdminSidebar />
      <div className="row" id="topsize">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Create An Institute</h1>
                <p>Create Your Institute By Filling Up This Single Form!</p>
                <form
                  className="row g-3 register-form mt-4"
                  onSubmit={registerInstitute}
                >
                  <div className="col-md-6">
                    <label htmlFor="inputEmail4" className="form-label">
                      Institute Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          Name: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputPassword4" className="form-label">
                      Institute Type
                    </label>
                    <select
                      id="inputType"
                      className="form-select"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          Type: e.target.value,
                        });
                      }}
                    >
                      <option value=" ">Choose...</option>
                      <option value="Public">Public</option>
                      <option value="Private">Private</option>
                    </select>
                  </div>
                  <div className="col-md-5">
                    <label className="form-label">Registration Number</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          RegdNo: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-7">
                    <label htmlFor="inputAddress" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          Address: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputCity" className="form-label">
                      City
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          City: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="inputState" className="form-label">
                      State
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          State: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label className="form-label">District</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          District: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputZip" className="form-label">
                      Zip
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          ZipCode: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="inputCountry" className="form-label">
                      Country
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setInstitute({
                          ...institute,
                          Country: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12">
                    <button type="submit" className="btn btn-info btn-lg">
                      {isbeingCreated ? "Creating..." : "Create"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={instituteimg} alt="instituteimg" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <h2 className="text-center mt-5">All Institutes</h2>
      <div className="table-responsive">
        <table className="table-bordered border-warning mt-2 mb-5">
          <thead>
            <tr>
              <th scope="col">Sr No.</th>
              <th scope="col">Name</th>
              <th scope="col">Type</th>
              <th scope="col">Regd. No.</th>
              <th scope="col">Address</th>
              <th scope="col">City</th>
              <th scope="col">District</th>
              <th scope="col">State</th>
              <th scope="col">Zip Code</th>
              <th scope="col">Country</th>
              <th scope="col">Created By</th>
              <th scope="col">Modify</th>
            </tr>
          </thead>
          <tbody>
            {fetchedData.documents &&
              fetchedData.documents.map((inst, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{inst.InstituteName}</td>
                  <td>{inst.InstituteType}</td>
                  <td>{inst.InstituteRegdNo}</td>
                  <td>{inst.InstituteAddress}</td>
                  <td>{inst.InstituteCity}</td>
                  <td>{inst.InstituteDistrict}</td>
                  <td>{inst.InstituteState}</td>
                  <td>{inst.InstituteZip}</td>
                  <td>{inst.InstituteCountry}</td>
                  <td>{inst.CreatedBy}</td>
                  <td>
                    <center>
                      <button
                        type="button"
                        className="btn btn-success mb-2"
                        onClick={() => handleModify(inst.$id)}
                      >
                        Modify
                      </button>
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => handleDelete(inst.$id)}
                      >
                        {isbeingDeleted ? "Deleting..." : "Delete"}
                      </button>
                    </center>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default CreateClass;
