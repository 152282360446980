import { useEffect } from "react";
import cardContents from "../components/FeatureContents";

const Features = () => {
  useEffect(() => {
    document.title = "Features | Make Me Master";
  }, []);
  return (
    <section>
      <h1 className="text-center mt-5 mb-5">Our Features</h1>
      <div className="row">
        {cardContents.map((card) => (
          <div className="col-sm-3" key={card.id}>
            <div className="card features-card">
              <div className="card-body">
                <h3>{card.title}</h3>
                <img src={card.image} alt={card.title} id="animateimg" />
                <p className="card-text">{card.text}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
