import { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import {
  account,
  databases,
  DatabaseId,
  AdminCollectionId,
  StudentCollectionId,
  TeacherCollectionId,
  ResetPasswordLink,
  ResetPasswordWwwLink,
} from "../../AppwriteConfig";
import forgotpasswordimg from "../../img/forgotpasswordimg.webp";
import Swal from "sweetalert2";
const ForgetPassword = () => {
  useEffect(() => {
    document.title = "Forget Password | Make Me Master";
  }, []);
  const [userEmail, setUserEmail] = useState("");
  const [isSendingResetEmail, setIsSendingResetEmail] = useState(false);

  const sendResetEmail = async (e) => {
    e.preventDefault();
    try {
      setIsSendingResetEmail(true);

      const [adminData, teacherData, studentData] = await Promise.all([
        databases.listDocuments(DatabaseId, AdminCollectionId),
        databases.listDocuments(DatabaseId, TeacherCollectionId),
        databases.listDocuments(DatabaseId, StudentCollectionId),
      ]);

      const userAdmin = adminData.documents.find(
        (doc) => doc.AdminEmail === userEmail
      );
      const userTeacher = teacherData.documents.find(
        (doc) => doc.TeacherEmail === userEmail
      );
      const userStudent = studentData.documents.find(
        (doc) => doc.StudentEmail === userEmail
      );

      let resetLink =
        window.location.hostname === "www.makememaster.in"
          ? ResetPasswordWwwLink
          : ResetPasswordLink;

      if (userAdmin || userTeacher || (userStudent && resetLink)) {
        await account.createRecovery(userEmail, resetLink);
        Swal.fire(
          "Password Reset Link Sent",
          "Please Check Your Inbox!",
          "success"
        );
      } else {
        Swal.fire(
          "No User Found!",
          "You Are Not A Registered User Of Us.",
          "error"
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSendingResetEmail(false);
    }
  };

  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <div className="inner">
                  <h1>Welcome To Forgot Password Section</h1>
                  <br />
                  <form method="post" className="register-form">
                    <div className="mb-3">
                      <label className="form-label">Your Email</label>
                      <input
                        type="email"
                        className="form-control"
                        onChange={(e) => {
                          setUserEmail(e.target.value);
                        }}
                        required
                      />
                    </div>
                    <button
                      type="submit"
                      className="btn btn-warning"
                      onClick={sendResetEmail}
                      disabled={isSendingResetEmail}
                    >
                      {isSendingResetEmail
                        ? "Sending Password Reset Email..."
                        : "Send Password Reset Email"}
                    </button>
                    <NavLink to="/Register" className="btn btn-info loginbtn">
                      {" "}
                      New User? Register Now
                    </NavLink>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img
                  src={forgotpasswordimg}
                  alt="forgotpasswordimg"
                  id="animateimg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgetPassword;
