import homeimg from "../img/home.webp";
import labsimg from "../img/labsimg.webp";
import assessmentimg from "../img/assessmentimg.webp";
import feesimg from "../img/feesimg.webp";
import analyticsimg from "../img/analyticsimg.webp";
import collaborationimg from "../img/collaborationimg.webp";
import privacyimg from "../img/privacyimg.webp";
import contentimg from "../img/contentimg.webp";
const cardContents = [
  {
    id: 1,
    title: "Course Management",
    image: homeimg,
    text: "Our robust Course Management system empowers educators to effortlessly create, organize, and manage courses while providing students with clear schedules and prerequisites, simplifying enrollment and enhancing the learning experience.",
  },
  {
    id: 2,
    title: "Virtual Labs",
    image: labsimg,
    text: "Experience hands-on learning like never before with our Virtual Labs, offering a diverse range of STEM simulations. Collaborate in real time with peers, conduct experiments online, and access a treasure trove of interactive experiences.",
  },
  {
    id: 3,
    title: "Secure Assessments",
    image: assessmentimg,
    text: "Conduct secure assessments with ease. Schedule and administer exams, implement proctoring and anti-plagiarism measures, and provide instant feedback while storing results securely for an efficient assessment process.",
  },
  {
    id: 4,
    title: "Fees & Financial Aid",
    image: feesimg,
    text: "Streamline your financial transactions with our secure payment gateway, convenient billing and invoicing, and flexible payment plans. Explore financial aid options and manage applications seamlessly.",
  },
  {
    id: 5,
    title: "Analytics & Support",
    image: analyticsimg,
    text: "Make informed decisions with our analytics tools, gaining insights into performance while benefiting from our responsive customer support and comprehensive documentation to ensure your success.",
  },
  {
    id: 6,
    title: "Collaboration Tools",
    image: collaborationimg,
    text: "Facilitate collaboration and engagement with interactive discussion forums and real-time chat tools. Foster meaningful interactions between students and faculty, enhancing the learning journey.",
  },
  {
    id: 7,
    title: "Accessibility & Privacy",
    image: privacyimg,
    text: "Our mobile-responsive design and inclusive accessibility features ensure learning is accessible to all. We prioritize data privacy and compliance with stringent privacy regulations.",
  },
  {
    id: 8,
    title: "Content Management",
    image: contentimg,
    text: "Effortlessly manage and access course materials with our Content Management system. Upload, organize, and streamline content delivery, providing easy access to educational resources.",
  },
];

export default cardContents;
