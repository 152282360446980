import { useEffect } from "react";
import thankyouimg from "../img/thankyouimg.webp";
import { NavLink } from "react-router-dom";
const ThankYou = () => {
  useEffect(() => {
    document.title = "Thank You | Make Me Master";
  }, []);
  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Thanks For Sending Us Message</h1>
                <h4>
                  We Will Soon Be Contacting You.Until Then Explore Our Features
                  That Might Help You A Lot...
                </h4>
                <NavLink className="btn subscribe-btn" to="/Home">
                  <h3>Go Home</h3>
                </NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={thankyouimg} alt="Thank You" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ThankYou;
