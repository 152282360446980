import { Routes, Route, Navigate } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import About from "./pages/About";
import Features from "./pages/Features";
import Pricing from "./pages/Pricing";
import Contact from "./pages/Contact";
import Login from "./pages/Login";
import Register from "./pages/Register";
import ErrorPage from "./pages/ErrorPage";
import AdminLogin from "./pages/LoginSystem/AdminLogin";
import TeacherLogin from "./pages/LoginSystem/TeacherLogin";
import StudentLogin from "./pages/LoginSystem/StudentLogin";
import AdminRegistration from "./pages/RegistrationSystem/AdminRegistration";
import TeacherRegistration from "./pages/RegistrationSystem/TeacherRegistration";
import StudentRegistration from "./pages/RegistrationSystem/StudentRegistration";
import ThankYou from "./pages/ThankYou";
import AdminDashboard from "./pages/Dashboard/Admin/AdminDashboard";
import TeacherDashboard from "./pages/Dashboard/Teacher/TeacherDashboard";
import StudentDashboard from "./pages/Dashboard/Student/StudentDashboard";
import VerifyEmail from "./pages/RegistrationSystem/VerifyEmail";
import BackToTop from "./components/BackToTop";
import ForgetPassword from "./pages/LoginSystem/ForgetPassword";
import ResetPassword from "./pages/LoginSystem/ResetPassword";
import CreateInstitute from "./pages/Dashboard/Admin/CreateInstitute";
import CreateClass from "./pages/Dashboard/Admin/CreateClass";
import CreateDocument from "./pages/Dashboard/Admin/CreateDocument";
import ModifyInstitute from "./pages/Dashboard/Admin/ModifyInstitute";

const App = () => {
  return (
    <div className="app-container">
      <Navbar />
      <div className="container" id="install-container">
        <Routes>
          {/* Normal Pages */}
          <Route exact path="/Home" element={<Home />} />
          <Route exact path="/About" element={<About />} />
          <Route exact path="/Features" element={<Features />} />
          <Route exact path="/Pricing" element={<Pricing />} />
          <Route exact path="/Contact" element={<Contact />} />
          <Route exact path="/Login" element={<Login />} />
          <Route exact path="/Register" element={<Register />} />
          <Route exact path="/ThankYou" element={<ThankYou />} />
          <Route exact path="/404" element={<ErrorPage />} />
          {/* Login System */}
          <Route exact path="/AdminLogin" element={<AdminLogin />} />
          <Route exact path="/TeacherLogin" element={<TeacherLogin />} />
          <Route exact path="/StudentLogin" element={<StudentLogin />} />
          {/* Registration System */}
          <Route
            exact
            path="/AdminRegistration"
            element={<AdminRegistration />}
          />
          <Route
            exact
            path="/TeacherRegistration"
            element={<TeacherRegistration />}
          />
          <Route
            exact
            path="/StudentRegistration"
            element={<StudentRegistration />}
          />
          {/* Dashboard */}
          <Route exact path="/Dashboard/Admin" element={<AdminDashboard />} />
          <Route
            exact
            path="/Dashboard/Teacher"
            element={<TeacherDashboard />}
          />
          <Route
            exact
            path="/Dashboard/Student"
            element={<StudentDashboard />}
          />
          {/* Admin Dashboard */}
          <Route
            exact
            path="/Dashboard/Admin/CreateInstitute"
            element={<CreateInstitute />}
          />
          <Route
            exact
            path="/Dashboard/Admin/CreateClass"
            element={<CreateClass />}
          />
          <Route
            exact
            path="/Dashboard/Admin/CreateDocument"
            element={<CreateDocument />}
          />
          {/* Email Verification */}
          <Route exact path="/VerifyEmail" element={<VerifyEmail />} />
          {/* Forget Password */}
          <Route exact path="/ForgetPassword" element={<ForgetPassword />} />
          {/* Reset Password */}
          <Route exact path="/ResetPassword" element={<ResetPassword />} />
          {/* Modify Institute*/}
          <Route
            exact
            path="/Dashboard/Admin/ModifyInstitute"
            element={<ModifyInstitute />}
          />
          {/* Extras */}
          <Route exact path="/" element={<Navigate to="/Home" replace />} />
          <Route path="*" element={<Navigate to="/404" replace />} />
        </Routes>
      </div>
      <Footer />
      <BackToTop />
    </div>
  );
};

export default App;
