import React, { useEffect, useState } from "react";
import {
  account,
  databases,
  DatabaseId,
  StudentCollectionId,
} from "../AppwriteConfig";
import { useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import closebtn from "../img/close.gif";
import modeicon from "../img/modeicon.gif";
import menubtn from "../img/menu.gif";
import gotoimg from "../img/gotoimg.gif";
import homeicon from "../img/homeicon.gif";
import logo from "../img/icon.png";

const StudentSidebar = () => {
  const [loggedInUserName, setLoggedInUserName] = useState("");
  const [isBeingLoggedOut, setIsBeingLoggedOut] = useState(false);
  const [theme, setTheme] = useState("light-mode");
  const [userStudent, setUserStudent] = useState(null); // Added userStudent state
  const navigate = useNavigate();

  // Fetch User Data
  const fetchUserDetails = async () => {
    try {
      const response = await account.get();
      const userEmail = response.email;
      setLoggedInUserName(response.name);
      await fetchStudentData(userEmail);
      localStorage.setItem("UserEmail", userEmail);
    } catch (error) {
      console.log(error); // Failure
      navigate("/Login");
      Swal.fire("Unauthorized", "Please Login To Continue", "error");
    }
  };

  // Validation Check
  const fetchStudentData = async (userEmail) => {
    try {
      const response = await databases.listDocuments(
        DatabaseId,
        StudentCollectionId
      );
      const studentData = response.documents.find(
        (doc) => doc.StudentEmail === userEmail
      );
      if (!studentData) {
        Swal.fire(
          "UnAuthorized",
          "Please Login With Correct Credentials!",
          "error"
        );
        account.deleteSession("current");
        localStorage.removeItem("UserEmail");
        navigate("/Login");
        window.location.reload();
      } else {
        setUserStudent(studentData);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, []);

  useEffect(() => {
    if (loggedInUserName && userStudent) {
      localStorage.setItem("UserName", loggedInUserName);
      console.log(loggedInUserName);
      Swal.fire(`Welcome ${loggedInUserName}`, "To Make Me Master!", "success");
    }
  }, [loggedInUserName, userStudent]);
  const handleLogOut = async () => {
    setIsBeingLoggedOut(true);
    try {
      await account.deleteSession("current");
      setIsBeingLoggedOut(false);
      navigate("/Login");
      window.location.reload();
    } catch (error) {
      console.log(error);
      Swal.fire("Logout Failed!", `${error}`, "error");
    }
  };

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme === "light-mode" ? "dark-mode" : "light-mode"
    );
  };

  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    setTheme(darkModeMediaQuery.matches ? "dark-mode" : "light-mode");
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  return (
    <>
      <button
        className="btn btn-info menubtn"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasScrolling"
        aria-controls="offcanvasScrolling"
      >
        <img src={menubtn} alt="menu" className="icon-btn" />
        &nbsp;Menu
      </button>
      <div id={`${theme}`}>
        <div
          className="offcanvas offcanvas-start offcanvasofsidebar"
          data-bs-scroll="true"
          data-bs-backdrop="false"
          tabIndex="-1"
          id="offcanvasScrolling"
          aria-labelledby="offcanvasScrollingLabel"
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasScrollingLabel">
              <img src={logo} alt="logo" className="logo" />
              &nbsp; Make Me Master
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
            >
              <img src={closebtn} alt="close" className="icon-btn" />
            </button>
          </div>
          <div className="offcanvas-body">
            <h4>Hi {loggedInUserName}!</h4>
            <NavLink
              className={(navData) =>
                navData.isActive ? "menu_active" : "nav-link"
              }
              to="/Dashboard/Student"
            >
              <img src={homeicon} alt="homeicon" className="icon-btn" /> Home
            </NavLink>

            <button className="nav-link" onClick={toggleTheme}>
              <img src={modeicon} alt="modeicon" className="icon-btn" />
              {theme === "light-mode" ? "Dark Mode" : "Light Mode"}
            </button>
            <button className="btn btn-danger mt-3" onClick={handleLogOut}>
              {isBeingLoggedOut ? "Logging Out" : "Log Out"}
            </button>
          </div>
        </div>
      </div>
      <button className="btn btn-success modebtn" onClick={toggleTheme}>
        <img src={modeicon} alt="modeicon" className="icon-btn" />
        {theme === "light-mode" ? "Dark Mode" : "Light Mode"}
      </button>
      <button className="btn btn-danger logoutbtn" onClick={handleLogOut}>
        <img src={gotoimg} alt="logoutimg" className="icon-btn" />{" "}
        {isBeingLoggedOut ? "Logging Out" : "Log Out"}
      </button>
    </>
  );
};

export default StudentSidebar;
