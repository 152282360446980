import { Client, Databases, Account } from "appwrite";
export const client = new Client();
client
  .setEndpoint("https://appwrite.makememaster.in/v1")
  .setProject("65050bcece92baf76e66");
export const databases = new Databases(client);
export const account = new Account(client);

//Values:
export const DatabaseId = "65050c3fc0f94de39a5f";
export const AdminCollectionId = "65050c499acf968d2df3";
export const TeacherCollectionId = "65050c53919d6d44fc57";
export const StudentCollectionId = "65050c5c8bb9c53cba7e";
export const InstituteCollectionId = "651aa7f2b4af2005ace8";
//Verification Link
// export const VerifyLink = "http://localhost:3000/VerifyEmail";
export const VerifyLink = "https://makememaster.in/VerifyEmail";
export const VerifyWwwLink = "https://www.makememaster.in/VerifyEmail";
// export const ResetPasswordLink = "http://localhost:3000/ResetPassword";
export const ResetPasswordLink = "https://makememaster.in/ResetPassword";
export const ResetPasswordWwwLink = "https://www.makememaster.in/ResetPassword";
