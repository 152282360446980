import { useEffect } from "react";
import aboutimg from "../img/aboutimg.webp";
const About = () => {
  useEffect(() => {
    document.title = "About | Make Me Master";
  }, []);
  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1 className="mt-5">About Make Me Master</h1>
                <p>
                  At Make Me Master,our mission is to transform education by
                  harnessing the power of technology. Our all-in-one online
                  education system empowers administrators, faculty, and
                  students with a comprehensive suite of features. From
                  intuitive course management to immersive virtual labs, secure
                  exam portals, and convenient fees payment options, we&apos;ve
                  crafted a platform that redefines the learning experience.
                  With accessibility, collaboration, and support at its core,
                  &apos;Make Me Master&apos; is your gateway to limitless
                  knowledge. Join us in shaping the future of education, where
                  every learner can aspire to mastery.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={aboutimg} alt="about" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
