import { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  account,
  DatabaseId,
  AdminCollectionId,
  StudentCollectionId,
  TeacherCollectionId,
  databases,
} from "../AppwriteConfig";
import logo from "../img/icon.png";
import menubtn from "../img/menu.gif";
import closebtn from "../img/close.gif";
import homeicon from "../img/homeicon.gif";
import abouticon from "../img/abouticon.gif";
import featuresicon from "../img/featuresicon.gif";
import pricingicon from "../img/pricingicon.gif";
import contacticon from "../img/contacticon.gif";
import loginicon from "../img/loginicon.gif";
import registericon from "../img/registericon.gif";
import modeicon from "../img/modeicon.gif";
import gotoimg from "../img/gotoimg.gif";
import logoutimg from "../img/logoutimg.gif";
import Swal from "sweetalert2";
const Navbar = () => {
  const [navOpen, setNavOpen] = useState(false);
  const location = useLocation();
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState("");
  const [goingToDashboard, setGoingToDashboard] = useState(false);
  const [isBeingLoggedOut, setIsBeingLoggedOut] = useState(false);
  const navigate = useNavigate();
  //Account Details
  const fetchUserDetails = () => {
    const loggedinUser = account.get();
    loggedinUser
      .then(function (response) {
        setIsUserLoggedIn(true);
        setLoggedInUserEmail(response.email);
      })
      .catch(function (error) {
        console.log(error.message); // Failure
      });
  };

  // Function to handle logout
  const handleLogOut = () => {
    setIsBeingLoggedOut(true);
    const logoutUser = account.deleteSession("current");
    logoutUser
      .then(function (response) {
        console.log(response); // Success
        Swal.fire("Logged Out", "You Have Been Logged Out!", "success");
        setIsBeingLoggedOut(false);
        window.location.reload();
      })
      .catch(function (error) {
        console.log(error); // Failure
        Swal.fire("Logout Failed!", `${error}`, "error");
      });
  };

  function toggleNav() {
    setNavOpen((state) => !state);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const [theme, setTheme] = useState("light-mode");
  const toggleTheme = () => {
    theme === "light-mode" ? setTheme("dark-mode") : setTheme("light-mode");
  };

  // Detect system's dark mode preference and set the initial theme.
  useEffect(() => {
    const darkModeMediaQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );
    if (darkModeMediaQuery.matches) {
      setTheme("dark-mode");
    } else {
      setTheme("light-mode");
    }
  }, []);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  //Fetch the logged in data only 1 time
  useEffect(() => {
    fetchUserDetails();
  }, []);

  //Handle Go To Dashboard
  const goToDashboard = async (e) => {
    try {
      setGoingToDashboard(true);
      // Admin
      const fetchAdminData = databases.listDocuments(
        DatabaseId,
        AdminCollectionId
      );
      const adminDataResponse = await fetchAdminData;

      const userAdmin = adminDataResponse.documents.find(
        (doc) => doc.AdminEmail === loggedInUserEmail
      );

      //Teacher
      const fetchTeacherData = databases.listDocuments(
        DatabaseId,
        TeacherCollectionId
      );
      const TeacherDataResponse = await fetchTeacherData;

      const userTeacher = TeacherDataResponse.documents.find(
        (doc) => doc.TeacherEmail === loggedInUserEmail
      );

      //Student
      const fetchStudentData = databases.listDocuments(
        DatabaseId,
        StudentCollectionId
      );
      const StudentDataResponse = await fetchStudentData;

      const userStudent = StudentDataResponse.documents.find(
        (doc) => doc.StudentEmail === loggedInUserEmail
      );

      if (userAdmin) {
        navigate("/Dashboard/Admin");
      } else if (userTeacher) {
        navigate("/Dashboard/Teacher");
      } else if (userStudent) {
        navigate("/Dashboard/Student");
      } else {
        Swal.fire("Invalid Credentials", "Please Enter Valid Details", "error");
        return;
      }
    } catch (error) {
      console.error(error);
      return;
    }
    setGoingToDashboard(false);
  };

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg sticky-top text-center"
        id={`${theme}`}
        style={{
          display: location.pathname.startsWith("/Dashboard/") ? "none" : "",
        }}
      >
        <div className="container-fluid">
          <NavLink className="navbar-brand" to="/">
            <img
              src={logo}
              alt="Logo"
              className="logo"
              width="30"
              height="24"
            />
            &nbsp;Make Me Master
          </NavLink>
          <button
            onClick={toggleNav}
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <img src={menubtn} alt="menu" className="icon-btn" />
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex="-1"
            id="offcanvasNavbar"
            aria-labelledby="navbarOffcanvasNavbarLabel"
            data-bs-dismiss={navOpen ? "offcanvas" : "none"}
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
                <img
                  src={logo}
                  alt="Logo"
                  className="logo"
                  width="30"
                  height="24"
                />
                &nbsp;Make Me Master
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              >
                <img src={closebtn} alt="close" className="icon-btn" />
              </button>
            </div>
            <div className="offcanvas-body" id="main-bar">
              <div className="navbar-nav">
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  to="/Home"
                >
                  <img src={homeicon} alt="homeicon" className="icon-btn" />{" "}
                  Home
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  to="/About"
                >
                  <img src={abouticon} alt="abouticon" className="icon-btn" />{" "}
                  About
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  to="/Features"
                >
                  <img
                    src={featuresicon}
                    alt="featuresicon"
                    className="icon-btn"
                  />{" "}
                  Features
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  to="/Pricing"
                >
                  <img
                    src={pricingicon}
                    alt="pricingicon"
                    className="icon-btn"
                  />{" "}
                  Pricing
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  to="/Contact"
                >
                  <img
                    src={contacticon}
                    alt="contacticon"
                    className="icon-btn"
                  />{" "}
                  Contact
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  style={{ display: isUserLoggedIn ? "none" : "" }}
                  to="/Login"
                >
                  <img src={loginicon} alt="loginicon" className="icon-btn" />{" "}
                  Login
                </NavLink>
                <NavLink
                  className={(navData) =>
                    navData.isActive ? "menu_active" : "nav-link"
                  }
                  style={{ display: isUserLoggedIn ? "none" : "" }}
                  to="/Register"
                >
                  <img
                    src={registericon}
                    alt="registericon"
                    className="icon-btn"
                  />{" "}
                  Register
                </NavLink>
                <button className="btn btn-info" onClick={toggleTheme}>
                  <img src={modeicon} alt="modeicon" className="icon-btn" />
                  {theme === "light-mode" ? "Dark Mode" : "Light Mode"}
                </button>
                <button
                  className="btn btn-success "
                  style={{ display: isUserLoggedIn ? "" : "none" }}
                  onClick={goToDashboard}
                >
                  <img src={gotoimg} alt="gotoimg" className="icon-btn" />{" "}
                  {goingToDashboard ? "Going..." : "Dashboard"}
                </button>
                <button
                  className="btn btn-danger"
                  style={{ display: isUserLoggedIn ? "" : "none" }}
                  onClick={handleLogOut}
                >
                  <img src={logoutimg} alt="logoutimg" className="icon-btn" />{" "}
                  {isBeingLoggedOut ? "Logging Out" : "Log Out"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
