import { useState, useEffect } from "react";
import {
  VerifyLink,
  VerifyWwwLink,
  account,
  databases,
  DatabaseId,
  TeacherCollectionId,
} from "../../AppwriteConfig";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";

import registerimg from "../../img/register.webp";
// import TeacherSidebar from "../../components/TeacherSidebar";

const TeacherRegistration = () => {
  useEffect(() => {
    document.title = "Teacher Registration | Make Me Master";
  }, []);
  const [user, setUser] = useState({
    name: "",
    phone: "",
    teacherId: "",
    email: "",
    password: "",
  });

  const [isRegistering, setIsRegistering] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  let verifyLink =
    window.location.hostname === "www.makememaster.in"
      ? VerifyWwwLink
      : VerifyLink;

  // Registration Function Starts Here

  const registerUser = async (e) => {
    e.preventDefault();
    setIsRegistering(true);

    // Check if all fields are filled
    for (const key in user) {
      if (user[key] === "") {
        Swal.fire("Error", "Please Fill In All Fields!", "error");
        setIsRegistering(false);
        return;
      }
    }

    const userId = user.phone;
    const docId = Date.now().toString();
    try {
      // Fetching All Datas
      const fetchedData = await databases.listDocuments(
        DatabaseId,
        TeacherCollectionId
      );
      // console.log(fetchedData.documents);
      if (fetchedData.documents.length > 0) {
        for (let i = 0; i < fetchedData.documents.length; i++) {
          if (
            fetchedData.documents[i].TeacherId === user.id ||
            fetchedData.documents[i].TeacherEmail === user.email ||
            fetchedData.documents[i].TeacherMobNo === user.phone
          ) {
            Swal.fire("Error", "User Already Exists!", "error");
            setIsRegistering(false);
            return;
          }
        }
      }
      // Register in Auth
      await account.create(userId, user.email, user.password, user.name);
      //Create An Email Session
      await account.createEmailSession(user.email, user.password);
      //Send Verification Link
      account.createVerification(verifyLink);
      Swal.fire(
        "Verification Email Sent",
        "Please Check Your Inbox!",
        "success"
      );
      //Storing Datas In A Var.
      const documentData = {
        TeacherId: user.teacherId,
        TeacherName: user.name,
        TeacherMobNo: user.phone,
        TeacherEmail: user.email,
        TeacherPassword: user.password,
      };

      // Register in the database
      await databases.createDocument(
        DatabaseId,
        TeacherCollectionId,
        docId,
        documentData
      );
    } catch (error) {
      console.log(error); // Failure
      Swal.fire("Failed!", `${error.message}`, "error");
    } finally {
      setIsRegistering(false);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section>
      {/* <TeacherSidebar/> */}
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1 className="text-center">Register As An Teacher</h1>
                <form
                  method="post"
                  className="register-form"
                  onSubmit={registerUser}
                >
                  <div className="mb-3">
                    <label className="form-label">Your Name</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setUser({
                          ...user,
                          name: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Mobile No.</label>
                    <input
                      type="number"
                      className="form-control"
                      maxLength={25}
                      required
                      onChange={(e) => {
                        setUser({
                          ...user,
                          phone: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Teacher ID</label>
                    <input
                      type="text"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setUser({
                          ...user,
                          teacherId: e.target.value.toUpperCase(),
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Email ID</label>
                    <input
                      type="email"
                      className="form-control"
                      required
                      onChange={(e) => {
                        setUser({
                          ...user,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Password</label>
                    <input
                      type={showPassword ? "text" : "password"} // Toggle input type
                      className="form-control"
                      required
                      onChange={(e) => {
                        setUser({
                          ...user,
                          password: e.target.value,
                        });
                      }}
                    />
                    <button
                      type="button"
                      className="btn btn-success btn-sm mt-2"
                      onClick={togglePasswordVisibility}
                    >
                      {showPassword ? "Hide Password" : "Show Password"}
                    </button>
                  </div>
                  <button type="submit" className="btn btn-warning">
                    {isRegistering ? "Registering..." : "Register"}
                  </button>
                  <NavLink className="btn btn-info loginbtn" to="/TeacherLogin">
                    Already Registered? Login Now
                  </NavLink>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={registerimg} alt="register" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeacherRegistration;
