import { useEffect } from "react";
import contactimg from "../img/contactimg.webp";
const Contact = () => {
  useEffect(() => {
    document.title = "Contact | Make Me Master";
  }, []);

  function ShowHideDiv() {
    var selectedChoice = document.getElementById("selectedChoice");
    var selectedPlan = document.getElementById("selectedPlan");
    selectedPlan.style.display =
      selectedChoice.value === "Yes" ? "block" : "none";
  }

  return (
    <section>
      <div className="row mt-5">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Contact Make Me Master</h1>
                <p>Just Fill Out This Form To Contact Us:</p>
                <form
                  action="https://formsubmit.co/e827bd8fd2a95d7e7576547bb1aad862"
                  method="POST"
                  className="contact-form"
                >
                  {/* Email Preferences */}
                  <div>
                    <input
                      type="hidden"
                      name="_subject"
                      value="An User Has Just Send You A Message From Make Me Master's Contact Form.Please Have A Look!"
                    />
                    <input type="hidden" name="_template" value="table" />
                    <input type="hidden" name="_captcha" value="false" />
                    <input
                      type="hidden"
                      name="_next"
                      value="https://www.makememaster.in/ThankYou"
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Name</label>
                    <input
                      type="text"
                      name="Name"
                      className="form-control"
                      id="exampleInputText1"
                      aria-describedby="TextHelp"
                      required
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Your Mobile Number(With International Code)
                    </label>
                    <input
                      type="text"
                      name="Mobile-Number"
                      className="form-control"
                      id="exampleInputText1"
                      aria-describedby="TextHelp"
                      placeholder="+91 1234567890"
                      required
                    />
                    <div id="phoneHelp" className="form-text">
                      We&apos;ll Never Share Your Mobile Number With Anyone
                      Else.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Email Id</label>
                    <input
                      type="email"
                      name="Email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="abc@mail.com"
                      required
                    />
                    <div id="emailHelp" className="form-text">
                      We&apos;ll Never Share Your Email With Anyone Else.
                    </div>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Discuss About Our Plans?
                    </label>
                    <select
                      id="selectedChoice"
                      name="About-Plans"
                      className="form-select"
                      onChange={ShowHideDiv}
                      defaultValue="No"
                      required
                    >
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </div>
                  <div
                    id="selectedPlan"
                    style={{ display: "none" }}
                    className="mb-3"
                  >
                    <label className="form-label">If Yes, Which One?</label>
                    <select
                      name="Selected-Plan"
                      className="form-select"
                      required
                    >
                      <option value="None">Select Your Plan</option>
                      <option value="Basic">Basic</option>
                      <option value="Pro">Pro</option>
                      <option value="Ultimate">Ultimate</option>
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Your Message</label>
                    <textarea
                      type="text"
                      name="Message"
                      className="form-control"
                      id="exampleInputText1"
                      aria-describedby="TextHelp"
                      required
                    />
                  </div>
                  <center>
                    <button type="submit" className="btn btn-success">
                      Send Message
                    </button>
                  </center>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={contactimg} alt="contact" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
