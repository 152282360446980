import contentimg from "../img/contentimg.webp";
import labsimg from "../img/labsimg.webp";
import assessmentimg from "../img/assessmentimg.webp";

const cardContents = [
  {
    id: 1,
    title: "Basic Plan",
    price: "$99/month",
    exfeature: "Course Management",
    image: contentimg,
    desc: "Our robust Course Management system empowers educators to effortlessly create, organize, and manage courses while providing students with clear schedules and prerequisites, simplifying enrollment and enhancing the learning experience.",
    benefit1: "Create and manage courses with ease.",
    benefit2: "Track student progress and performance.",
    benefit3: "Provide clear schedules and prerequisites.",
    benefit4: "Enhance the learning experience.",
  },
  {
    id: 2,
    title: "Pro Plan",
    price: "$149/month",
    exfeature: "Virtual Labs",
    image: labsimg,
    desc: "Experience hands-on learning like never before with our Virtual Labs, offering a diverse range of STEM simulations. Collaborate in real time with peers, conduct experiments online, and access a treasure trove of interactive experiences.",
    benefit1: "Conduct hands-on experiments.",
    benefit2: "Collaborate with peers in real time.",
    benefit3: "Access a diverse range of STEM simulations.",
    benefit4: "Enhance the learning experience.",
  },
  {
    id: 3,
    title: "Ultimate Plan",
    price: "$199/month",
    exfeature: "Secure Assessments",
    image: assessmentimg,
    desc: "Conduct secure assessments with ease. Schedule and administer exams, implement proctoring and anti-plagiarism measures, and provide instant feedback while storing results securely for an efficient assessment process.",
    benefit1: "Conduct secure exams.",
    benefit2: "Implement proctoring and anti-plagiarism measures.",
    benefit3: "Provide instant feedback.",
    benefit4: "Store results securely.",
  },
];

export default cardContents;
