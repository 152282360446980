import { useEffect } from "react";
import homeimg from "../img/home.webp";
const Home = () => {
  useEffect(() => {
    document.title = "Welcome To Make Me Master";
  }, []);
  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>Welcome To Make Me Master!</h1>
                <h4>An All-In-One Online Education System</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img src={homeimg} alt="home" id="animateimg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
