import { useEffect } from "react";
import admindashboardimg from "../../../img/adminlogin.webp";
import AdminSidebar from "../../../components/AdminSidebar";

const AdminDashboard = () => {
  useEffect(() => {
    document.title = "Admin Dashboard | Make Me Master";
  }, []);

  const loggedInUserName = localStorage.getItem("UserName");

  var today = new Date();
  var curHr = today.getHours();
  var greet;
  if (curHr < 12) {
    greet = "Good Morning";
  } else if (curHr < 16) {
    greet = "Good Afternoon";
  } else {
    greet = "Good Evening";
  }

  return (
    <section>
      <AdminSidebar />
      <div className="row" id="topsize">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <h1>
                  {greet}&nbsp;{loggedInUserName}!
                </h1>
                <h4>Overview:</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img
                  src={admindashboardimg}
                  alt="admindashboard"
                  id="animateimg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AdminDashboard;
