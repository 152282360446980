import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import adminloginimg from "../img/adminlogin.webp";
import teacherloginimg from "../img/teacherlogin.webp";
import studentloginimg from "../img/studentlogin.webp";
const Register = () => {
  useEffect(() => {
    document.title = "Register | Make Me Master";
  }, []);

  return (
    <section>
      <h1 className="text-center mt-5 mb-5">Registration Portal</h1>
      <div className="row">
        <div className="col-sm-4">
          <div className="card features-card">
            <div className="card-body">
              <h2>Register As An Admin</h2>
              <img src={adminloginimg} alt="adminlogin" id="animateimg" />
              <p className="card-text"></p>
              <NavLink className=" btn login-btn" to="/AdminRegistration">
                <h5>Sign Up</h5>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card features-card">
            <div className="card-body">
              <h2>Register As A Teacher</h2>
              <img src={teacherloginimg} alt="teacherlogin" id="animateimg" />
              <p className="card-text"></p>
              <NavLink className=" btn login-btn" to="/TeacherRegistration">
                <h5>Sign Up</h5>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card features-card">
            <div className="card-body">
              <h2>Register As A Student</h2>
              <img src={studentloginimg} alt="studentlogin" id="animateimg" />
              <p className="card-text"></p>
              <NavLink className=" btn login-btn" to="/StudentRegistration">
                <h5>Sign Up</h5>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Register;
