import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import {
  account,
  databases,
  DatabaseId,
  AdminCollectionId,
  StudentCollectionId,
  TeacherCollectionId,
} from "../../AppwriteConfig";
import resetpasswordimg from "../../img/resetpasswordimg.webp";
import Swal from "sweetalert2";

const ResetPassword = () => {
  useEffect(() => {
    document.title = "Reset Password | Make Me Master";
  }, []);

  const navigate = useNavigate();
  const [password, setPassword] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [docId, setDocId] = useState("");
  const [userType, setUserType] = useState(""); // Store the user type (Admin, Teacher, Student)
  const [isSettingNewPassword, setIsSettingNewPassword] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const userId = urlParams.get("userId");
  const secret = urlParams.get("secret");

  useEffect(() => {
    // Fetch user data based on userId and set the userType and docId
    const fetchUserData = async () => {
      try {
        const adminResponse = await databases.listDocuments(
          DatabaseId,
          AdminCollectionId
        );
        if (adminResponse) {
          const adminData = adminResponse.documents.find(
            (doc) => doc.AdminMobNo === userId
          );
          if (adminData) {
            setUserType("Admin");
            setDocId(adminData.$id);
            return;
          }
        }

        const teacherResponse = await databases.listDocuments(
          DatabaseId,
          TeacherCollectionId
        );
        if (teacherResponse) {
          const teacherData = teacherResponse.documents.find(
            (doc) => doc.TeacherMobNo === userId
          );
          if (teacherData) {
            setUserType("Teacher");
            setDocId(teacherData.$id);
            return;
          }
        }

        const studentResponse = await databases.listDocuments(
          DatabaseId,
          StudentCollectionId
        );
        if (studentResponse) {
          const studentData = studentResponse.documents.find(
            (doc) => doc.StudentMobNo === userId
          );
          if (studentData) {
            setUserType("Student");
            setDocId(studentData.$id);
          }
        }
      } catch (error) {
        console.error(error);
        Swal.fire(
          "We Were Unable To Fetch Your Account Details!",
          `${error.message}`,
          "error"
        );
        return;
      }
    };

    fetchUserData();
  }, [userId]);

  const updatePassword = async (e) => {
    e.preventDefault();
    if (password.newPassword !== password.confirmPassword) {
      Swal.fire(
        "Passwords Do Not Match",
        "Please Make Sure Your Passwords Match!",
        "error"
      );
      return;
    }

    if (!userType) {
      Swal.fire(
        "No User Found!",
        "You Are Not A Registered User Of Us.",
        "error"
      );
      return;
    }

    try {
      setIsSettingNewPassword(true);

      await account.updateRecovery(
        userId,
        secret,
        password.newPassword,
        password.confirmPassword
      );

      const updateData = {};

      if (userType === "Admin") {
        updateData.AdminPassword = password.confirmPassword;
        await databases.updateDocument(
          DatabaseId,
          AdminCollectionId,
          docId,
          updateData
        );
        Swal.fire(
          "Password Changed",
          "Your Password Has Been Changed!",
          "success"
        );
        navigate("/AdminLogin");
      } else if (userType === "Teacher") {
        updateData.TeacherPassword = password.confirmPassword;
        await databases.updateDocument(
          DatabaseId,
          TeacherCollectionId,
          docId,
          updateData
        );
        Swal.fire(
          "Password Changed",
          "Your Password Has Been Changed!",
          "success"
        );
        navigate("/TeacherLogin");
      } else if (userType === "Student") {
        updateData.StudentPassword = password.confirmPassword;
        await databases.updateDocument(
          DatabaseId,
          StudentCollectionId,
          docId,
          updateData
        );
        Swal.fire(
          "Password Changed",
          "Your Password Has Been Changed!",
          "success"
        );
        navigate("/StudentLogin");
      }
      setIsSettingNewPassword(false);
    } catch (error) {
      console.error(error);
      Swal.fire(
        "We Were Unable To Update Your Password",
        `${error.message}`,
        "error"
      );
      setIsSettingNewPassword(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <section>
      <div className="row">
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <div className="inner">
                  <h1>Welcome To Reset Password Section</h1>
                  <br />
                  <form method="post" className="register-form">
                    <div className="mb-3">
                      <label className="form-label">New Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={password.newPassword}
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            newPassword: e.target.value,
                          });
                        }}
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label className="form-label">Confirm New Password</label>
                      <input
                        type={showPassword ? "text" : "password"}
                        className="form-control"
                        value={password.confirmPassword}
                        onChange={(e) => {
                          setPassword({
                            ...password,
                            confirmPassword: e.target.value,
                          });
                        }}
                        required
                      />
                      <button
                        type="button"
                        className="btn btn-success btn-sm mt-2"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? "Hide Password" : "Show Password"}
                      </button>
                    </div>
                    <button
                      type="submit"
                      className="btn btn-warning"
                      onClick={updatePassword}
                      disabled={isSettingNewPassword}
                    >
                      {isSettingNewPassword
                        ? "Updating Your Password..."
                        : "Update Your Password"}
                    </button>
                    <NavLink to="/Register" className="btn btn-info loginbtn">
                      {" "}
                      New User? Register Now
                    </NavLink>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm">
          <div className="outer">
            <div className="middle">
              <div className="inner">
                <img
                  src={resetpasswordimg}
                  alt="resetpasswordimg"
                  id="animateimg"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ResetPassword;
