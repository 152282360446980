import { useEffect } from "react";
import { NavLink } from "react-router-dom";
import adminloginimg from "../img/adminlogin.webp";
import teacherloginimg from "../img/teacherlogin.webp";
import studentloginimg from "../img/studentlogin.webp";
const Login = () => {
  useEffect(() => {
    document.title = "Login | Make Me Master";
  }, []);

  return (
    <section>
      <h1 className="text-center mt-5 mb-5">Login Portal</h1>
      <div className="row">
        <div className="col-sm-4">
          <div className="card features-card">
            <div className="card-body">
              <h2>Login As An Admin</h2>
              <img src={adminloginimg} alt="adminlogin" id="animateimg" />
              <p className="card-text"></p>
              <NavLink className=" btn login-btn" to="/AdminLogin">
                <h5>Sign In</h5>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card features-card">
            <div className="card-body">
              <h2>Login As A Teacher</h2>
              <img src={teacherloginimg} alt="teacherlogin" id="animateimg" />
              <p className="card-text"></p>
              <NavLink className=" btn login-btn" to="/TeacherLogin">
                <h5>Sign In</h5>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="col-sm-4">
          <div className="card features-card">
            <div className="card-body">
              <h2>Login As A Student</h2>
              <img src={studentloginimg} alt="studentlogin" id="animateimg" />
              <p className="card-text"></p>
              <NavLink className=" btn login-btn" to="/StudentLogin">
                <h5>Sign In</h5>
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
